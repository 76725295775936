
import common from '@/mixin/common.js'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'r-news',
  mixins: [common],
  data() {
    return {
      productBgImage: require('@/assets/news/news_bg.jpg'),
	  pageNum: 1,
	  pageSize: 6
    }
  },
  mounted() {
    this.getNewsList({
		pageNum: this.pageNum,
		pageSize: this.pageSize
	});
    // this.scrollToTop();
  },
  methods: {
    ...mapActions('news', ['getNewsList']),
	LoadMoreList(){ //加载更多
		this.pageSize += 3;
		 this.getNewsList({
			 pageNum: this.pageNum,
			 pageSize: this.pageSize
		 })
	},
    showDetail(params = null) {
      if (!params || !params.code) {
        return;
      }
	  console.log('新闻详情',params)
      this.$router.push(`/news/detail/${params.code}`);
    },
  },
  computed: {
    ...mapGetters('news', ['newsList', 'haveMore']),
  }
}